import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import westEdLaurel from 'stile-shared/assets/images/home/west_ed_laurel.svg';

import { IllustrationCardsBlock } from 'stile-shared/src/components/2020/blocks/IllustrationCardsBlock';
import { ImageCardsBlock } from 'stile-shared/src/components/2020/blocks/ImageCardsBlock';
import { MediaBlock } from 'stile-shared/src/components/2020/blocks/MediaBlock';
import { TeaserBlock } from 'stile-shared/src/components/2020/blocks/TeaserBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { TwinklingStarsBlock } from 'stile-shared/src/components/2020/blocks/TwinklingStarsBlock';
import { ButtonLink } from 'stile-shared/src/components/2020/common/ButtonLink';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { H2, P } from 'stile-shared/src/components/2020/common/Primitives';
import { Testimonial } from 'stile-shared/src/components/2020/common/Testimonial';
import { TrialButton } from 'stile-shared/src/components/2020/common/TrialButton';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { CurriculumBlock } from 'components/blocks/CurriculumBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="The world-class science curriculum for Oregon"
      description="Stile is a leading digital learning platform offering science teaching resources to enhance students’ and teachers’ classroom experiences. Learn more here!"
      slug={props.location.pathname}
    />
  );
}

function HomePage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <TeaserBlock
        contentMax="430px"
        headingLevel="h1"
        title="The science curriculum that teachers love"
        video={{
          text: 'Hear from Jovana (2 mins)',
          url: 'https://youtu.be/V0aDHtUdwyk',
          coverImage: (
            <StaticImage
              loading="eager"
              src="../../../stile-shared/assets/images/what-teachers-are-saying/icms_video_cover.jpg"
              alt="Jovana is shown sitting on a table in her classroom, smiling at the camera"
            />
          ),
        }}
        button={
          <TrialButton gtagEvent="enquire_now_us">Connect with a Curriculum Specialist</TrialButton>
        }
      >
        <P>
          Stile is the only core science curriculum that’s kept up-to-date with the latest science.
        </P>

        <P>
          Teachers make it their own with our powerful, beautiful and adaptable teaching and
          learning platform.
        </P>
      </TeaserBlock>

      <TextBlock textAlign="center" title="An innovative second-generation NGSS curriculum">
        <P>
          Stile makes it a breeze to teach a true NGSS curriculum. It gives you the confidence that
          you’re covering the standards to the appropriate level, while also providing the analytics
          you need to know which of your students are meeting the standards, and which need
          additional support.
        </P>
        <Testimonial
          text="The materials consistently integrate the DCIs, SEPs, and CCCs for the grade-band as well
          as explicitly aligning with the nature of science."
          author="Oregon Department of Education adoption evaluating committee"
        ></Testimonial>
      </TextBlock>
      <ImageCardsBlock
        cards={[
          {
            title: 'Instructional materials',
            text: 'Beautiful, interactive science lessons',
            image: (
              <StaticImage
                src="../../../stile-shared/assets/images/home/in_class.png"
                alt="Closeup of a laptop screen showing an interactive cell diagram"
              />
            ),
          },

          {
            title: 'Labs',
            text: 'Kits for hands-on learning',
            image: (
              <StaticImage
                src="../../../stile-shared/assets/images/home/in_labs_us.png"
                alt="Two students conduct an experiment in their classroom"
              />
            ),
          },

          {
            title: 'Revision workbooks',
            text: 'Notetaking and study skills',
            image: (
              <StaticImage
                src="../../../stile-shared/assets/images/home/after_class.png"
                alt="Closeup of someone writing in a Stile X booklet"
              />
            ),
          },
          {
            title: 'Professional learning',
            text: 'The key to evidence-based science teaching',
            image: (
              <StaticImage
                src="../../../stile-shared/assets/images/home/professional_learning.png"
                alt="A Stile staff member leaning in to help a teacher in a training session"
              />
            ),
          },
          {
            title: 'Responsive support',
            text: 'Get taken care of by a team of Curriculum specialists',
            image: (
              <StaticImage
                src="../../../stile-shared/assets/images/home/concierge.png"
                alt="A support staff member wearing a call headset and smiling"
              />
            ),
          },
        ]}
      />

      <TwinklingStarsBlock>
        {/* image is decorative and does not require alt text */}
        <img style={{ width: '150px' }} src={westEdLaurel} alt="" />
        <H2>Top-rated by WestEd</H2>
        <P>
          Stile’s <em>The Importance of Biodiversity</em> unit earned a rating of High Quality NGSS
          Design if Improved by the NextGenScience cadre of expert reviewers, placing it in the top
          1% of units that they review.
        </P>
        <P>
          <Link
            url="https://www.nextgenscience.org/resources/middle-school-stile-importance-biodiversity"
            variant="inherit"
            openInNewTab
          >
            Read WestEd’s report
          </Link>{' '}
          and the corresponding EQuIP Rubric for Science evaluation report.
        </P>
      </TwinklingStarsBlock>

      <TextBlock
        textAlign="center"
        title="Rediscover the joy of teaching"
        media={
          <MediaBlock as="div" layout="montage-two">
            <StaticImage
              src="../../../stile-shared/assets/images/home/technology_empowers_01.jpg"
              alt="A teacher is up the front of a science classroom, teaching students at their desks"
            />
            <StaticImage
              src="../../../stile-shared/assets/images/home/technology_empowers_02.png"
              alt="A teacher gesturing to a projected screen behind her"
            />
          </MediaBlock>
        }
      >
        <P>
          Stile gives new teachers the support they need and experienced teachers the flexibility
          they deserve. Stile does not replace teachers. Instead, it empowers teachers to bring
          their ideas to life and better serve the needs of their students.
        </P>
      </TextBlock>

      <TeaserBlock
        contentMax="430px"
        variant="beige"
        title="Science evolves, and Stile evolves with it"
        text="Stile is constantly updated to incorporate the latest science news and global issues important to your students. When teaching with Stile your lessons are never out-of-date."
        testimonial={{
          text: 'Stile is engaging and exciting, and it’s relevant, and I love that it’s constantly changing based on what’s happening in the world. You’re not printing a textbook and giving it to us for the next 25 years, instead Stile content constantly adapts, and that makes it relevant for the kids.',
          author: 'Alexis Gunnel',
          origin: 'Vaughn Middle School, Reno, Nevada',
        }}
        lessons={[
          {
            category: 'Science News',
            title: 'How to change the path of an asteroid',
            url: 'https://stileapp.com/au/library/publishers/stile/compilations/science-ngss/6202f5d2-910a-4966-91c4-2c91aac5b600/grade/supplementary/preview/how-to-change-the-path-of-an-a/p5I_',
            image: (
              <StaticImage
                src="../../../stile-shared/assets/images/home/lesson_dart.jpg"
                alt="A computer generated image of an asteroid about to collide with a satellite"
              />
            ),
          },
          {
            category: 'Cells',
            title: 'Lab grown burgers',
            url: 'https://stileapp.com/au/library/publishers/stile/compilations/science-ngss/6065632a-b968-41c5-8ed2-bc6eb466b0a7/preview/introduction-battle-of-the-bur/uUnu',
            image: (
              <StaticImage
                src="../../../stile-shared/assets/images/home/lesson_cells.png"
                alt="A closeup of gloved hands holding a petri dish with a small amount of pink liquid in it"
              />
            ),
          },
          {
            category: 'Ecosystems',
            title: 'Cleaning our oceans',
            url: 'https://stileapp.com/au/library/publishers/stile/compilations/science-ngss/60a74be0-8f47-4e65-ab32-748df820166b/grade/7/preview/introduction-the-problem-of-pl/Ps2L',
            image: (
              <StaticImage
                src="../../../stile-shared/assets/images/home/lesson_ecosystems.png"
                alt="An artwork featuring a whale composed of outlined rubbish items, and a background that appears like it is underwater"
              />
            ),
          },
          {
            category: 'Science News',
            title: 'The influence of social media',
            url: 'https://stileapp.com/au/library/publishers/stile/compilations/science-ngss/6202f5d2-910a-4966-91c4-2c91aac5b600/preview/the-influence-of-social-media/2vH-',
            image: (
              <StaticImage
                src="../../../stile-shared/assets/images/home/lesson_social.png"
                alt="A network of colourful dots, most of which are blue, with orange ones connecting to form the shape of the logos of several popular social media platforms"
              />
            ),
          },
        ]}
      />

      <TeaserBlock
        contentSide="right"
        contentMax="430px"
        variant="beige"
        title="Customized for your unique needs"
        lessons={[
          {
            title: 'Murder hornets in Washington',
            image: (
              <StaticImage
                src="../../../stile-shared/assets/images/home/washington_hornets.png"
                alt=""
              />
            ),
          },
          {
            title: 'Earthquakes in San Francisco',
            image: (
              <StaticImage
                src="../../../stile-shared/assets/images/home/san_fran_earthquakes.png"
                alt=""
              />
            ),
          },
          {
            title: 'The Fly Geyser in Nevada',
            image: (
              <StaticImage
                src="../../../stile-shared/assets/images/home/nevada_fly_geyser.png"
                alt=""
              />
            ),
          },
          {
            title: 'Ghost towns in Oregon',
            image: (
              <StaticImage
                src="../../../stile-shared/assets/images/home/oregon_ghost_town.png"
                alt=""
              />
            ),
          },
        ]}
      >
        <P>
          Our team works with yours to customize the Stile Curriculum to include local phenomena
          that are meaningful to your students.
        </P>

        <Testimonial
          text="The kids really love being able to look outside and see what they’re learning; they love to connect science to their world here in Reno."
          author="Alexis Gunnel"
          origin="Vaughn Middle School, Reno, Nevada"
        ></Testimonial>
      </TeaserBlock>

      <TeaserBlock
        contentMax="430px"
        variant="beige"
        title="Live student data at your fingertips"
        text="Stile’s award-winning teaching and learning platform provides teachers with critical learning insights as they are teaching. Progress monitoring is easy as teachers can quickly and efficiently catch misconceptions in the moment, address class knowledge gaps, and gather student assessment information in real-time, as students are working."
        button={
          <ButtonLink url="/what-is-stile/technology-for-teaching/" openInNewTab>
            Learn more →
          </ButtonLink>
        }
        image={
          <StaticImage
            src="../../../stile-shared/assets/images/home/progress_monitoring.jpg"
            alt="A teacher bends down to see what a student is pointing to on her screen"
          />
        }
      />

      <TeaserBlock
        contentSide="right"
        contentMax="430px"
        title="Foster student collaboration"
        text="Just like real scientists and engineers, Stile’s curriculum has students collaborating on research projects, debating scientific ideas, and sharing their learning reflections with one another. Stile helps teachers to foster safe collaborative environments, supporting student social and emotional well-being, such that even the most shy of students will build confidence and begin to flourish in a collaborative environment."
        testimonial={{
          text: 'Stile has been so amazing! It really is so much more student-centered than our current curriculum that I am almost giddy.',
          author: '8th Grade Science Teacher',
          origin: 'Reynolds School District',
        }}
        image={
          <StaticImage
            src="../../../stile-shared/assets/images/home/student_collaboration.jpg"
            alt="Two students conduct an experiment together in their classroom"
          />
        }
      />

      <TeaserBlock
        contentMax="430px"
        variant="beige"
        title="Boost reading, writing, and math"
        text="Reading, writing, and math are not confined to the walls of ELA and math classrooms. Stile’s science curriculum promotes the growth and development of reading, writing, and math for all students, helping them to more meaningfully engage in science discussions and statistical analysis."
        button={
          <ButtonLink url="/why-choose-stile/boost-reading-writing-and-math/" openInNewTab>
            Learn more →
          </ButtonLink>
        }
        testimonial={{
          text: 'I have one really smart kid, really auditory but reads at a first-grade level. He loves the word-by-word highlighting and audio support. With Stile, he can take the quiz all by himself now! I also have one student who just arrived who only speaks Spanish. This makes it easier for her. It’s the part I love best about Stile too.',
          author: '6th Grade Science Teacher',
          origin: 'Gladstone School District',
        }}
        image={
          <StaticImage
            src="../../../stile-shared/assets/images/home/boost_reading_writing.jpg"
            alt="Students complete Stile X work in their classroom"
          />
        }
      />

      <CurriculumBlock></CurriculumBlock>
      <IllustrationCardsBlock
        title="You'll see the difference"
        cards={[
          {
            title: 'Educators better able to meet diverse learners’ needs',
            text: 'Easy-to-use editing tools allow educators to quickly and easily customize a lesson to better suit the needs of individual students.',
            image: (
              /* image is decorative and does not require alt text */
              <StaticImage
                layout="fixed"
                width={150}
                src="../../../stile-shared/assets/images/home/spot_editing.png"
                alt=""
              />
            ),
          },
          {
            title: 'Students receive targeted feedback, more often',
            text: 'Educators can see student work against fully-worked model answers, allowing them to provide more timely, specific feedback.',
            image: (
              /* image is decorative and does not require alt text */
              <StaticImage
                layout="fixed"
                width={150}
                src="../../../stile-shared/assets/images/home/spot_feedback.png"
                alt=""
              />
            ),
          },
          {
            title: 'Building a culture of data-informed instruction',
            text: 'Live, per-question analytics and performance insights help educators identify student misconceptions earlier than ever.',
            image: (
              /* image is decorative and does not require alt text */
              <StaticImage
                layout="fixed"
                width={150}
                src="../../../stile-shared/assets/images/home/spot_data.png"
                alt=""
              />
            ),
          },
        ]}
      >
        <P>
          Read more about <Link url="/why-choose-stile/benefits/">the benefits</Link> of introducing
          Stile to your school.
        </P>
      </IllustrationCardsBlock>

      <NextPageBlock path="/what-is-stile/stile-classroom/" />
      <CtaBlock />
    </PageLayout>
  );
}

export default HomePage;
